<template>
  <div class="activities">
    <div class="beijingtupian5">
     <div class="waitao">
      <h6 class="title27">CORPORATE ACTIVITIES</h6>
      <h6 class="title28">公司活动</h6>
        <router-link :to="'/Culture'" class="fanhui"><img src ="../assets/fanhuiicon.svg" style="position:absolute;top:12px;left:17px"><p class="title29">返回上一级</p></router-link>
     </div>
    </div>  
     <div style="height:120px"></div>
    <div class="middle12">
      <div class="huodong"  v-for="(item,index) in  activeList" :key="index">
        <img :src ="item.image" style="width:360px;height:190px;margin-top:20px;margin-bottom:20px">
        <h6 class="title30">{{item.title}}</h6>
        <p class="intro66">发布于{{item.date}}</p>
        <p class="intro67">{{item.describe}}</p>
        <div class="read"  @click="goFirst(item._id)">阅读全文<img src ="../assets/ckgdicon.svg" style="position:relative;top:-15.7px;left:72px"></div>
      </div>   
    </div>
    <div style="height:120px"></div>
  <!-- 底部内容 -->
    <vbottom></vbottom>
  </div>
</template> 
<script>
import {activeList} from "@/api/index" ;
import vbottom from "../components/bottom";
export default {
  data() {
    return {
       activeList:[]
    }
  },
  mounted() {
  this.getActiveList()
  },
  components: { vbottom },
  methods:{
  getActiveList(){
    activeList().then((data) => {
       this.activeList =  data.list
       console.log(data)
   
    });
  },
  goFirst(_id) {
    this.$router.push({
        path:'/moreActs',
        query:{
          _id:_id
        }
      })
   } 
  }
}
</script> 
<style scoped>
.waitao{
  position: relative;
  width: 1180px;
  margin:auto;
}
.beijingtupian5{
  background: url('../assets/banner5@2X.png');
  width:100%;
  height: 288px;
  background-size:cover;
}
.title27{
  width: 1180px;
  height: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #CCCCCC;
  line-height: 14px;
  padding-top: 117px;
  text-align: center;
}
.title28{
  width: 1180px;
  height: 30px;
  font-size: 30px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 39px;
  margin-top: 10px;
  text-align: center;
}
.fanhui{
  width: 140px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #FFFFFF;
  position: absolute;
  top:201px;
  left:520px;
}
.title29{
  width: 80px;
  height: 16px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 16px;
  position:absolute;
  top:12px;
  left:43px;
}
.middle12{
  width: 1180px;
  height:2198px;
  margin: 0 auto;
  display: flex; 
  flex-direction:column;
  justify-content:center;
}
.huodong{
  width:1180px;
  height:230px;
  position: relative;
  border-bottom:1px solid #E2E3E4;
}
.huodong:last-child{
  border-bottom: none;
}
.title30{
  width: 252px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 18px;
  position: absolute;
  top:40px;
  left: 400px;
}
.intro66{
  width: 151px;
  font-size: 14px;
  font-weight: 400;
  color: #CCCCCC;
  line-height: 14px;
  position: absolute;
  top:68px;
  left: 400px;
}
.intro67{
  width: 780px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  position: absolute;
  top:102px;
  left: 400px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.read{
  width: 64px;
  font-size: 16px;
  font-weight: 400;
  color: #005FFF;
  line-height: 16px;
  position:absolute;
  top:174px;  
  left:400px;
  cursor:pointer;
}
</style>